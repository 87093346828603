const TableTool = {
    setColumn(data) {
        let columns = data
        if (columns && columns.length) {
            columns.forEach((column) => {
                switch (column.type) {
                    case 'elevatorName':
                        column.minWidth = 180
                        break
                    case 'villageName':
                        column.minWidth = 100
                        break
                    case 'levelName':
                        column.minWidth = 80
                        break
                    case 'datetime':
                        column.minWidth = 130
                        break
                    case 'faultName':
                        column.minWidth = 120
                        break
                    case 'faultNum':
                        column.minWidth = 80
                        break
                    case 'modeName':
                        column.minWidth = 100
                        break
                    case 'statusName':
                        column.minWidth = 80
                        break
                    case 'address':
                        column.minWidth = 100
                        break
                    case 'operate':
                        column.minWidth = 80
                        column.fixed = 'right'
                        break
                }
            })
        }
        return columns
    }
};

module.exports = {
    TableTool: TableTool,
};