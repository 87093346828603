import { Message } from 'view-design'

// 获取多少天后日期 获取多少天前日期
export function formatDate(num) {
  var now = new Date()
  var nowTime = now.getTime()
  var oneDayTime = 24 * 60 * 60 * 1000
  var ShowTime = nowTime + num * oneDayTime
  var myDate = new Date(ShowTime)
  var y = myDate.getFullYear() // 年
  var m = myDate.getMonth() + 1 // 月
  var d = myDate.getDate() // 日
  return [y, m, d].map(padStartConvert).join('-')
}

export function padStartConvert(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function formatDateYMD(date, format) {
  if (!date) return
  if (!format) format = 'yyyy-MM-dd'
  switch (typeof date) {
    case 'string':
      date = new Date(date.replace(/-/, '/'))
      break
    case 'number':
      date = new Date(date)
      break
  }
  if (!(date instanceof Date)) return
  var dict = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    MM: ('' + (date.getMonth() + 101)).substr(1),
    dd: ('' + (date.getDate() + 100)).substr(1),
    HH: ('' + (date.getHours() + 100)).substr(1),
    mm: ('' + (date.getMinutes() + 100)).substr(1),
    ss: ('' + (date.getSeconds() + 100)).substr(1)
  }
  return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function() {
    return dict[arguments[0]]
  })
}

export function formatDateVal(val) {
  if (val && val.length) return val.replace('T', ' ')
  return ''
}

export function formatDateToStartOfDay(dateStr) {
  if (!dateStr) return dateStr
  if (dateStr && dateStr.indexOf('00:00:00') > -1) return dateStr
  return dateStr + ' 00:00:00'
}

export function formatDateToEndOfDay(dateStr) {
  if (!dateStr) return dateStr
  if (dateStr && dateStr.indexOf('23:59:59') > -1) return dateStr
  return dateStr + ' 23:59:59'
}
