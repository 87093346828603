<template>
    <div>
        <img style="display: block; width: 100%; height:auto;" v-if="showSoundLight && showAlarmGif" src="@/assets/alarm/alert.gif" />
        <!-- <div @click="onSwitchBtnTapped">{{eventSwitch?'播报已打开':'播报已关闭'}}</div> -->
        <audio muted ref="audio" @ended="onPlayEnd">
            Your browser does not support the audio element.
        </audio>
    </div>
</template>
<script>
import { searchFaultList } from '@/api/alarm/index.js'
export default {
    data() {
        return {
            showSoundLight:false,
            isPlaying: false,
            isLoading: false,
            interval: null,
            formItem: {
                eType: null,
                vProjectId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-0) + '',
                dtEndTime: '',
                iUncivilizedBehaviorFlag: 0,
                iElevatorType: 1,
                iFaultType: '',
                iInstallStatus: 1,
                // vEventType: "8",
                iStatus: 0,
                villageId: ''
            },
            faultList: [],


            retryCount: 0,
        }
    },
    props: {
        showAlert: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        eventSwitch() {
            return this.$store.state.event.eventSwitch
        },
        eventList() {
            return this.$store.state.event.eventList
        },
        closeFaultIds() {
            return this.$store.state.event.closeFaultIds
        },
        eventControl() {
            return this.$store.state.event.eventControl
        },
        user() {
            return this.$store.state.user.user
        },
        /**
         * 显示alert的 并且没有关闭的有则show 否则hide
         */
        showAlarmGif() {
            let events = this.eventList
            let allFaultIds = this.eventList.map((item) => {
                return item.v_fault_id
            })
            let allClose = true
            if (this.closeFaultIds.length <= 0) {
                allClose = false
            } else {
                allFaultIds.forEach((item) => {
                    if (!this.closeFaultIds.includes(item)) {
                        allClose = false
                    }
                })
            }
            return  this.showAlert && !allClose && allFaultIds.length
        },

    },
    mounted() {
        this.timer.start()
    },
    methods: {
        /**
         * 开启监控
         */
        async startMonitor() {
            if (this.isLoading || !this.user || Object.keys(this.user).length <= 0) {
                this.timer.add({
                    ref: this,
                    func: "startMonitor",
                    timeout: 3,
                });
                return
            }
            this.isLoading = true
            try {
                const res = await searchFaultList(this.formItem)
                this.faultList = res.info.list
                this.updateAlarmByList(this.faultList)
                this.updateSoundAndLightByList(this.faultList)
            } finally {
                this.isLoading = false
                this.timer.add({
                    ref: this,
                    func: "startMonitor",
                    timeout: 3,
                });
            }
        },


        updateAlarmByList(list) {
            let faultIds = list.map(item => { return item.v_fault_id }).join(',')
            let control = this.eventControl
            // 之前有eventList 但是消除 所以清空
            if (control.hasAlertkey.length  && list.length == 0) {
                this.$store.dispatch('resetEventControl')
                this.$store.dispatch('updateEventList', { data: [] })
            } else {
                if (faultIds.length == 0) return false
                // 如果提醒过 
                if (control.hasAlertkey && this.checkHasAlert(control.hasAlertkey, faultIds))
                    return false

                //提醒
                this.$store.dispatch('updateEventControl', {
                    data: {
                        hasAlertkey: faultIds,
                    }
                })
                this.$store.dispatch('updateEventList', { data: list })
                return true
            }
        },

        updateSoundAndLightByList(list) {
            let hasTrap = this.faultList.find((fault) => {
                return fault.i_fault_type == 7 || fault.i_fault_type == 8
            })
            let soundSwitchOn = this.eventSwitch
            if (soundSwitchOn && hasTrap && this.showAlarmGif) {
                this.showSoundLight = true
                this.statPlay()
            } else {
                this.showSoundLight = false
                this.stopIfNeeded()
            }
        },

        /**
         * 检查是否提示过，如果有一个没有提示则提示
         */
        checkHasAlert(alertKeys, faultIds) {
            let aks = alertKeys.split(',')
            let fids = faultIds.split(',')
            let hasAlert = true
            for (var i = 0; i < fids.length; i++) {
                if (!aks.includes(fids[i])) {
                    hasAlert = false
                    break
                }
            }
            return hasAlert
        },
        statPlay() {
            if (this.isPlaying) return
            this.play()
        },
        play() {
            this.$refs.audio.src = require('@/assets/alarm/audio/alarm.aac')
            this.$refs.audio.muted = false
            this.$refs.audio.play()
            this.isPlaying = true
        },
        stop() {
            if (this.$refs.audio) {
                this.$refs.audio.pause()
                this.$refs.audio.currentTime = 0
            }
            this.isPlaying = false
        },
        stopIfNeeded() {
            if (this.isPlaying) this.stop()
        },
        openSwitch() {
            this.$refs.audio.src = require('@/assets/alarm/audio/muted.aac')
            this.$refs.audio.muted = true
            this.$refs.audio.loop = false
            this.$refs.audio.play()
            this.$store.dispatch('updateEventSwitch', { data: true })
        },
        closeSwitch() {
            this.stopIfNeeded()
            this.$store.dispatch('updateEventSwitch', { data: false })
        },
        onSwitchBtnTapped() {
            this.eventSwitch ? this.closeSwitch() : this.openSwitch()
        },
        onPlayEnd() {
            console.log('onPlayEnd')
            this.isPlaying = false
        },

        mockFaultList() {
            return [{
                "i_level": 3,
                "v_fault_id": "8451109881599950848",
                "groupLeasingStatus": 0,
                "eType": "MX201",
                "i_mode_status": 0,
                "i_status_name": "已恢复",
                "i_fault_num": 1,
                "i_level_name": "中等Lv3",
                "villageName": "美利山",
                "v_fault_name": "门区外停梯",
                "i_mode_status_name": "正常运行",
                "v_elevator_code": "MX5893",
                "i_status": 0,
                "dt_report_time": "2024-02-28T17:17:03",
                "i_fault_type": "6",
                "v_address": "美利山翠渝路55号174-2",
                "v_elevator_name": "美利山翠渝路55号174-2",
                "groupLeasingResult": 0,
                "villageId": "8435155707167047680"
            }]
        }
    },
    destroyed() {
        this.timer.remove(this)
    }
}
</script>
<style scoped></style>