let count = 1
const mock = {
    start(url) {
        count++
                    return this.mockFault(url)
        if (count < 15) {
            console.log('mockFault', count)
            return this.mockFault(url)
        } else if (count >= 15 && count < 25) {
            console.log('mockRepair', count)

            return this.mockRepair(url)
        } else if (count >= 25) {
            console.log('mockFaultDisappear', count)

            return this.mockFaultDisappear(url)
        }

    },

    mockFault(url) {
        if (url.indexOf('fault/searchFault') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "pageIndex": 1,
                    "pageSize": 10,
                    "totalRecordCnt": 0,
                    "list": [{
                        "i_level": 3,
                        "v_fault_id": "8461924909911441408",
                        "groupLeasingStatus": 0,
                        "eType": "MX201",
                        "i_mode_status": 0,
                        "i_status_name": "已恢复",
                        "i_fault_num": 1,
                        "i_level_name": "中等Lv3",
                        "villageName": "楚翘城",
                        "v_fault_name": "门区外停梯",
                        "i_mode_status_name": "正常运行",
                        "v_elevator_code": "MX5756",
                        "i_status": 1,
                        "dt_report_time": "2024-03-29T13:32:06",
                        "i_fault_type": "6",
                        "v_address": "雨花台区安德门大街61号软件谷智慧园4号楼西",
                        "v_elevator_name": "雨花台区安德门大街61号软件谷智慧园4号楼西",
                        "groupLeasingResult": 0,
                        "villageId": "8422843096761171968"
                    }]
                }
            }

            return Promise.resolve(resp)
        }

        // if (url.indexOf('elevator/countElevator') > -1) {
        //     let resp = {
        //         "code": "0000",
        //         "message": "处理成功!",
        //         "info": {
        //             "overhaulQuantity": 0,
        //             "normalQuantity": 5,
        //             "trappedPeopleQuantity": 0,
        //             "totalQuantity": 6,
        //             "faultQuantity": 1,
        //             "offlineQuantity": 0
        //         }
        //     }
        //     return Promise.resolve(resp)
        // }

        // if (url.indexOf('commonBigScreen/elevator/getElevatorsStatusWithMxData') > -1) {
        //     let resp = {
        //         "code": "0000",
        //         "message": "msg0000",
        //         "info": [{
        //                 "v_elevator_id": "8435155707443871751",
        //                 "v_elevator_code": "MX5892",
        //                 "i_on_line": 1,
        //                 "register_number": "31105001182012060013",
        //                 "v_address": "美利山翠渝路55号174-1",
        //                 "i_fault_status": 0,
        //                 "v_elevator_name": "美利山翠渝路55号174-1",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             },
        //             {
        //                 "v_elevator_id": "8435155707443871753",
        //                 "v_elevator_code": "MX5893",
        //                 "i_on_line": 1,
        //                 "register_number": "31105001182012080021",
        //                 "v_address": "美利山翠渝路55号174-2",
        //                 "i_fault_status": 1,
        //                 "v_elevator_name": "美利山翠渝路55号174-2",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             },
        //             {
        //                 "v_elevator_id": "8435155707443871747",
        //                 "v_elevator_code": "MX5890",
        //                 "i_on_line": 1,
        //                 "register_number": "31105002982016054780",
        //                 "v_address": "美利山翠渝路55号177-1",
        //                 "i_fault_status": 0,
        //                 "v_elevator_name": "美利山翠渝路55号177-1",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             },
        //             {
        //                 "v_elevator_id": "8435155707443871749",
        //                 "v_elevator_code": "MX5891",
        //                 "i_on_line": 1,
        //                 "register_number": "31105002982016054779",
        //                 "v_address": "美利山翠渝路55号177-2",
        //                 "i_fault_status": 0,
        //                 "v_elevator_name": "美利山翠渝路55号177-2",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             },
        //             {
        //                 "v_elevator_id": "8435155707431288832",
        //                 "v_elevator_code": "MX5888",
        //                 "i_on_line": 1,
        //                 "register_number": "31105002982017116413",
        //                 "v_address": "美利山翠渝路55号179-1",
        //                 "i_fault_status": 0,
        //                 "v_elevator_name": "美利山翠渝路55号179-1",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             },
        //             {
        //                 "v_elevator_id": "8435155707443871745",
        //                 "v_elevator_code": "MX5889",
        //                 "i_on_line": 1,
        //                 "register_number": "31105002982017116415",
        //                 "v_address": "美利山翠渝路55号179-2",
        //                 "i_fault_status": 0,
        //                 "v_elevator_name": "美利山翠渝路55号179-2",
        //                 "i_mode_status": 0,
        //                 "villageId": "8435155707167047680"
        //             }
        //         ]
        //     }
        //     return Promise.resolve(resp)
        // }
        return null
    },

    mockRepair(url) {
        if (url.indexOf('fault/searchFault') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "pageIndex": 1,
                    "pageSize": 10,
                    "totalRecordCnt": 0,
                    "list": [{
                        "i_level": 3,
                        "v_fault_id": "8451109881599950848",
                        "groupLeasingStatus": 0,
                        "eType": "MX201",
                        "i_mode_status": 0,
                        "i_status_name": "已恢复",
                        "i_fault_num": 1,
                        "i_level_name": "中等Lv3",
                        "villageName": "美利山",
                        "v_fault_name": "门区外停梯",
                        "i_mode_status_name": "正常运行",
                        "v_elevator_code": "MX5893",
                        "i_status": 0,
                        "dt_report_time": "2024-02-28T17:17:03",
                        "i_fault_type": "6",
                        "v_address": "美利山翠渝路55号174-2",
                        "v_elevator_name": "美利山翠渝路55号174-2",
                        "groupLeasingResult": 0,
                        "villageId": "8435155707167047680"
                    }]
                }
            }

            return Promise.resolve(resp)
        }

        if (url.indexOf('elevator/countElevator') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "overhaulQuantity": 0,
                    "normalQuantity": 5,
                    "trappedPeopleQuantity": 0,
                    "totalQuantity": 6,
                    "faultQuantity": 1,
                    "offlineQuantity": 0
                }
            }
            return Promise.resolve(resp)
        }

        if (url.indexOf('maixin/wuye/getElevatorInfo') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "bi_run_count": "31406",
                    "v_elevator_brand_name": "巨人通力",
                    "d_last_maintain_date": "2024-03-04",
                    "v_equipment_code": "31105001182012080021",
                    "last_maintain_date": "2024-03-04",
                    "next_inspect_date": "--",
                    "i_mode_status": 1,
                    "fault_status": "无故障",
                    "i_install_status": 1,
                    "real_time_fault": "无故障",
                    "v_elevator_id": "8435155707443871753",
                    "v_http_pt_codes": "null",
                    "fault_name": "无故障",
                    "property_safe_phone": "02363412082",
                    "i_maintain_days": 14,
                    "start_fault_time": "暂无",
                    "v_address": "美利山翠渝路55号174-2",
                    "v_elevator_name": "美利山翠渝路55号174-2",
                    "bi_door_count": "30292",
                    "i_elevator_type": 1,
                    "bi_run_distance_count": "907426",
                    "cumulative_days": 48,
                    "dt_install_time": "2024-01-19T00:00:00",
                    "maintain_principal_person": "唐林",
                    "maintain_company_name": "重庆伊仕顿智能电梯工程有限责任公司",
                    "i_on_line": 1,
                    "cumulative_running_time": 1152,
                    "i_elevator_use_type": 1,
                    "maintain_subordinate_person": "刘勇海",
                    "v_elevator_code": "MX5893",
                    "v_door_type": 0,
                    "property_company_name": "金科物业服务有限公司",
                    "bi_bend_count": "142640",
                    "i_fault_status": 0,
                    "property_safe_man": "赵明缙",
                    "elevator_type_name": "直梯",
                    "building_type": 1
                }
            }
            return Promise.resolve(resp)
        }

        if (url.indexOf('commonBigScreen/elevator/getElevatorsStatusWithMxData') > -1) {
            let resp = {
                "code": "0000",
                "message": "msg0000",
                "info": [{
                        "v_elevator_id": "8435155707443871751",
                        "v_elevator_code": "MX5892",
                        "i_on_line": 1,
                        "register_number": "31105001182012060013",
                        "v_address": "美利山翠渝路55号174-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号174-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871753",
                        "v_elevator_code": "MX5893",
                        "i_on_line": 1,
                        "register_number": "31105001182012080021",
                        "v_address": "美利山翠渝路55号174-2",
                        "i_fault_status": 1,
                        "v_elevator_name": "美利山翠渝路55号174-2",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871747",
                        "v_elevator_code": "MX5890",
                        "i_on_line": 1,
                        "register_number": "31105002982016054780",
                        "v_address": "美利山翠渝路55号177-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号177-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871749",
                        "v_elevator_code": "MX5891",
                        "i_on_line": 1,
                        "register_number": "31105002982016054779",
                        "v_address": "美利山翠渝路55号177-2",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号177-2",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707431288832",
                        "v_elevator_code": "MX5888",
                        "i_on_line": 1,
                        "register_number": "31105002982017116413",
                        "v_address": "美利山翠渝路55号179-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号179-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871745",
                        "v_elevator_code": "MX5889",
                        "i_on_line": 1,
                        "register_number": "31105002982017116415",
                        "v_address": "美利山翠渝路55号179-2",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号179-2",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    }
                ]
            }
            return Promise.resolve(resp)
        }
    },

    mockFaultDisappear(url) {
        if (url.indexOf('fault/searchFault') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "pageIndex": 1,
                    "pageSize": 10,
                    "totalRecordCnt": 0,
                    "list": []
                }
            }

            return Promise.resolve(resp)
        }

        if (url.indexOf('elevator/countElevator') > -1) {
            let resp = {
                "code": "0000",
                "message": "处理成功!",
                "info": {
                    "overhaulQuantity": 1,
                    "normalQuantity": 5,
                    "trappedPeopleQuantity": 0,
                    "totalQuantity": 6,
                    "faultQuantity": 0,
                    "offlineQuantity": 0
                }
            }
            return Promise.resolve(resp)
        }

        if (url.indexOf('commonBigScreen/elevator/getElevatorsStatusWithMxData') > -1) {
            let resp = {
                "code": "0000",
                "message": "msg0000",
                "info": [{
                        "v_elevator_id": "8435155707443871751",
                        "v_elevator_code": "MX5892",
                        "i_on_line": 1,
                        "register_number": "31105001182012060013",
                        "v_address": "美利山翠渝路55号174-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号174-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871753",
                        "v_elevator_code": "MX5893",
                        "i_on_line": 1,
                        "register_number": "31105001182012080021",
                        "v_address": "美利山翠渝路55号174-2",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号174-2",
                        "i_mode_status": 1,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871747",
                        "v_elevator_code": "MX5890",
                        "i_on_line": 1,
                        "register_number": "31105002982016054780",
                        "v_address": "美利山翠渝路55号177-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号177-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871749",
                        "v_elevator_code": "MX5891",
                        "i_on_line": 1,
                        "register_number": "31105002982016054779",
                        "v_address": "美利山翠渝路55号177-2",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号177-2",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707431288832",
                        "v_elevator_code": "MX5888",
                        "i_on_line": 1,
                        "register_number": "31105002982017116413",
                        "v_address": "美利山翠渝路55号179-1",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号179-1",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    },
                    {
                        "v_elevator_id": "8435155707443871745",
                        "v_elevator_code": "MX5889",
                        "i_on_line": 1,
                        "register_number": "31105002982017116415",
                        "v_address": "美利山翠渝路55号179-2",
                        "i_fault_status": 0,
                        "v_elevator_name": "美利山翠渝路55号179-2",
                        "i_mode_status": 0,
                        "villageId": "8435155707167047680"
                    }
                ]
            }
            return Promise.resolve(resp)
        }
        return null
    }
}
module.exports = {
    mock: mock,
};