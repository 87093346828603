<template>
    <div class="tips-wrapper" v-if="showTips">
        <Alert type="error" show-icon>
        温馨提示
        <span slot="desc">
          当前分辨率影响您的体验,建议您在电脑端浏览
        </span>
    </Alert>
    </div>
</template>
<script>
import { getPlatform } from '@/common/utils/index'
import resize from '@/components/mixins/resize'
export default {
    name: 'MXGlobalTips',
    mixins: [resize],
    data() {
        return {
            showTips: false,
            ignoreTips:false,
        }
    },
    mounted() {
        this.eventHandler = this.onResize
        this.checkShowTips()
    },
    methods: {
        onResize() {
            this.checkShowTips()
        },
        checkShowTips() {
            let w = document.documentElement.clientWidth
            let platform = getPlatform()
            this.showTips = (platform == 'mobile' && w <= 980 || w <= 500) && !this.ignoreTips
        },
        close() {
            this.ignoreTips = true
            this.showTips = false
        }
    }
}
</script>
<style lang="scss" scoped>
.tips-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:20px;
    color:#fff;
    padding:30px;
}
</style>