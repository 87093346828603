let globalInterval = null;
let queue = [];
const timer = {
  /**
   * 启动定时器扫描
   */
  start() {
    this.stop();
    globalInterval = setInterval(() => {
      this.scan();
    }, 1000);
  },

  /**
   * 停止定时器扫描
   */
  stop() {
    if (globalInterval) clearInterval(globalInterval);
    queue = [];
  },
  /**
   * job {ref:'',func:'',timeout:'','uniqueId':'唯一标识',bizParams:{}}
   * ref 调用者 func 执行的函数 timeout 多久执行 timeout如果为空的话默认1s
   * 加入队列的时候打上一个进队列的时间
   * bizParams 业务参数
   */
  add(job) {
    let find = queue.find((aJob) => {
      return (
        (job.uniqueId && aJob.uniqueId == job.uniqueId) ||
        (aJob.func == job.func && aJob.ref == job.ref)
      );
    });
    if (!find) {
      job.enqueTime = new Date().getTime();
      if (!job.timeout) job.timeout = 1;
      queue.push(job);
    }
  },

  remove(ref, func, uniqueId) {
    queue = queue.filter((aJob) => {
      return (
        (uniqueId && uniqueId != aJob.uniqueId) ||
        (func && ref && (aJob.func != func && aJob.ref != ref)) || (ref && !func && aJob.ref != ref)
      );
    });
  },

  /**
   * 扫描队列 到期的出来
   */
  scan() {
    //获取到出队列的任务
    let now = new Date().getTime();
    // console.log(`【FYLog】${now}扫描定时器队列个数${queue.length}`);
    let dequeJobs = queue.filter((aJob) => {
      return (now - aJob.enqueTime) / 1000 >= aJob.timeout;
    });

    let leftJobs = queue.filter((aJob) => {
      return !dequeJobs.includes(aJob);
    });

    queue = leftJobs;
    
    dequeJobs.forEach((aJob) => {
      aJob.ref[aJob.func](aJob.bizParams);
    });

  },

  mockApi() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });
  },
};

module.exports = {
  timer: timer,
};
