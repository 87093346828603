import store from '@/store'

const event = {
    state: {
        eventList: [],
        eventSwitch: false,
        eventControl: {
            hasAlertkey: '', //已经提醒过的故障标识
        },
        closeFaultIds:[], //手动关闭的faultId
    },
    getters: {
        getEventList: (state) => state.eventList,
        getEventSwitch: (state) => state.eventSwitch,
        getIgnoreFaultIds: (state) => state.ignoreFaultIds,
    },
    mutations: {
        setEventList(state, payload) {
            state.eventList = payload
        },
        setEventSwitch(state, payload) {
            state.eventSwitch = payload
        },
        setEventControl(state, payload) {
            state.eventControl = payload
        },
        setCloseFaultIds(state,payload) {
            let eventControl = state.eventControl
            if (eventControl.hasAlertkey && eventControl.hasAlertkey.length) {
                let list = eventControl.hasAlertkey.split(',')
                list.push(payload)
                eventControl.hasAlertkey = list.join(',')
            }else {
                eventControl.hasAlertkey = payload
            }
            let closeFaultIds = state.closeFaultIds
            if (closeFaultIds.length) 
                closeFaultIds.push(payload)
            else 
                closeFaultIds = [payload]
                state.closeFaultIds = closeFaultIds
        }
    },
    actions: {
        updateEventList({ commit }, reqData) {
            commit("setEventList", reqData && reqData.data ? reqData.data : [])
        },
        updateEventSwitch({ commit }, reqData) {
            commit("setEventSwitch", reqData && reqData.data ? reqData.data : false)
        },
        updateEventControl({ commit }, reqData) {
            console.log('调试 eventControl',reqData.data)
            commit("setEventControl", reqData && reqData.data ? reqData.data : false)
        },
        resetEventControl({ commit }, reqData) {
            commit("setEventControl", {
                hasAlertkey: '', //已经提醒过的故障标识
            })
        }
    },
}

export default event