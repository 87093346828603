import { formatDateToStartOfDay, formatDateToEndOfDay } from './date/index'
import { Message } from 'view-design'

let util = {
  debounceIdentify: 0,
  bounce(func, delay) {
    this.debounceIdentify && clearTimeout(this.debounceIdentify)
    this.debounceIdentify = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}

export function setTitle(title) {
  title = title || '物业'
  window.document.title = title
}

export function setMenu(routers, code) {
  /*
   *  @routers  redux管理的 appRouter和otherRouter 的路由表
   *  @code  用户类型   user.codetype 字段  值为 1，2，3，4
   *
   * */
  let menu = []
  routers.forEach(router => {
    const INDEX = router.children[0]
    if (INDEX.meta.role.indexOf(code) > -1) {
      let obj = {
        title: INDEX.meta.title,
        path: `${router.path}/${INDEX.path}`,
        icon: `el-icon-dogma-${INDEX.meta.icon}`
      }
      menu.push(obj)
    }
  })

  return menu
}

export function getCurrentPath(vm, router) {
  let currentPathArr = []

  if (router.name !== 'home') {
    if (router.name.indexOf('_index') > -1) {
      currentPathArr.push({
        title: router.meta.title,
        path: '',
        name: router.name
      })
    } else {
      router.matched.forEach(item => {
        let obj = {
          title: item.meta.title,
          path: item.path,
          name: item.name
        }
        if (item.path.indexOf('/:id') > -1) {
          obj.path = router.path
        }
        currentPathArr.push(obj)
      })
    }
  }

  return currentPathArr
}

export function mergeBarData(target, data) {
  console.log(target)
  console.log(data)
  if (target.legend && target.legend.data.length) {
    target.legend.data = data.map(first => {
      return first.name
    })
  }

  if (target.nest) {
    target.xAxis.data = data[0].value.map(first => {
      return first.name
    })

    target.series = data.map(first => {
      let obj = {
        name: first.name,
        type: 'bar',
        label: {
          normal: {
            show: true,
            position: 'top',
            formatter: '{c}'
          }
        },
        data: []
      }
      obj.data = first.value.map(second => {
        return second.value
      })
      return obj
    })
  } else {
    let categoryAxis =
      target.xAxis.type === 'category' ? target.xAxis : target.yAxis

    categoryAxis.data = data.map(first => {
      return first.name
    })

    target.series[0].data = data.map(first => {
      return first.value
    })
  }
  return target
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result
  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function validateAndFormatTime(startTime, endTime) {
  if (startTime && endTime) {
    if (startTime > endTime) {
      return {
        msg: '开始时间需要早于结束时间'
      }
    } else {
      return {
        startTime: formatDateToStartOfDay(startTime),
        endTime: formatDateToEndOfDay(endTime),
        msg: ''
      }
    }
  } else {
    return {
      startTime:
        startTime && startTime.length ? formatDateToStartOfDay(startTime) : '',
      endTime: endTime && endTime.length ? formatDateToEndOfDay(endTime) : '',
      msg: ''
    }
  }
}

export function formatTimeParams(startKey, endKey, form) {
  let params = JSON.parse(JSON.stringify(form))
  let data = validateAndFormatTime(params[startKey], params[endKey])
  if (data.msg.length) {
    Message.error({
      content: data.msg,
      duration: 5
    })
    delete params[startKey]
    delete params[endKey]
    form[startKey] = ''
    form[endKey] = ''
  } else {
    params[startKey] = data.startTime
    params[endKey] = data.endTime
  }
  return params
}

export function getPlatform() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag ? 'mobile' : 'pc'
}

export default util
