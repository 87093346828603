import Vue from 'vue'
import './plugins'
import http from './common/utils/http'
import router from './router'
import store from './store/index'
import Element from 'element-ui'
import Pagination from '@/components/Pagination'
// import './assets/styles/element-variables.scss'
import '@/common/styles/index.scss' // global css
import App from './App.vue'
import ViewUI, { Row } from 'view-design'
import 'view-design/dist/styles/iview.css'
import tblcomponents from '@/components/tblcomponents.vue'
import {
  formatDate,
  formatDateYMD,
  formatDateVal,
  formatDateToStartOfDay,
  formatDateToEndOfDay
} from './common/utils/date/index'
import { formatTimeParams } from './common/utils/index'

import { timer } from './common/utils/timer'
import { TableTool } from './common/utils/table-util'

import * as echarts from 'echarts'
import BaiduMap from '@/components/vue-baidu-map'
import HighchartsVue from 'highcharts-vue' // highcharts 组件库
import '@/permission' // permission control
import { getSystemSelection } from './api/global/globalApi' // 全局下拉框接口
import {
  parseTime,
  resetForm,
  handleTree,
  addBeginAndEndTime,
  divide,
  formatValue
} from '@/utils/ruoyi'

Vue.prototype.formatDate = formatDate
Vue.prototype.$formatDateYMD = formatDateYMD
Vue.prototype.$getSystemSelection = getSystemSelection

Vue.prototype.$echarts = echarts

Vue.prototype.formatDateVal = formatDateVal
Vue.prototype.formatDateToStartOfDay = formatDateToStartOfDay
Vue.prototype.formatDateToEndOfDay = formatDateToEndOfDay
Vue.prototype.parseTime = parseTime
Vue.prototype.formatValue = formatValue
Vue.prototype.timer = timer
Vue.use(HighchartsVue)
Vue.prototype.tableTool = TableTool
Vue.prototype.formatTimeParams = formatTimeParams

Vue.component('tblcomponents', tblcomponents)
Vue.component('Pagination', Pagination)

Vue.prototype.axios = http

Vue.use(ViewUI)
Vue.use(BaiduMap, {
  ak: 'ps3iOxIfketZQ3RQHzzXyRpOz6uGzLpY'
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
